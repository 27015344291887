<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">我的资源（小程序）</a>
          <i>></i>
          <a href="javascript:;" @click="goHerf()">我的课程</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">查看学员</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="学员姓名" class="searchboxItem ci-full">
              <span class="itemLabel">学员姓名:</span>
              <el-input
                v-model="userName"
                type="text"
                size="small"
                clearable
                placeholder="请输入学员姓名"
              />
            </div>
            <el-button
              style="margin-left: 20px"
              class="bgc-bv"
              round
              @click="getData()"
              >查询</el-button
            >
            <div class="btnBox" style="margin-left: 20px">
              <el-button class="bgc-bv" round @click="handleCreate('add')"
                >导出</el-button
              >
              <el-button class="bgc-bv" round @click="handleAddStudent()"
                >添加学员</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />

              <el-table-column
                label="学员姓名"
                align="center"
                prop="userName"
                show-overflow-tooltip
                min-width="100"
              />

              <el-table-column
                label="联系方式"
                align="center"
                prop="mobile"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="专业"
                align="center"
                prop="profession"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="工作单位"
                align="center"
                prop="workUnit"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="所属岗位"
                align="center"
                prop="professionTitle"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column label="学习进度" align="center">
                <template slot-scope="scope">
                  <span> {{ scope.row.schedule }} </span>
                </template>
              </el-table-column>
<!-- 屏蔽题库购买数量信息
              <el-table-column
                label="题库购买数量"
                align="center"
                prop="buyQuestionBankNum"
                show-overflow-tooltip
                min-width="100"
              />
              -->
              <el-table-column label="添加时间" align="center" min-width="150">
                <template slot-scope="scope"
                  >{{ scope.row.createTime | moment }}
                </template>
              </el-table-column>

              <el-table-column
                label="操作"
                align="center"
                width="100px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleDel(scope.row.courseUserId)"
                    >删除
                  </el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="55%"
      top="1%"
      :center="true"
      :before-close="doClose"
      :close-on-click-modal="false"
    >
      <div class="ovy-a" style="height: 500px">
        <el-form label-width="120px" ref="Form" :model="Form" :rules="rules">
          <el-form-item label="学员姓名" prop="userName">
            <el-input
              v-model="Form.userName"
              type="text"
              size="small"
              placeholder="请输入学员姓名"
            />
          </el-form-item>
          <el-form-item label="联系电话" prop="mobile" class="Classitem">
            <el-input
              v-model="Form.mobile"
              type="text"
              size="small"
              placeholder="请输入联系电话"
              @input="
                (val) => {
                  getMobile(val);
                }
              "
            />
            <div @click="showInfo" v-show="showtip" class="tip">
              {{ idcardmation || "" }}
            </div>
          </el-form-item>
          <el-form-item label="性别" prop="sex">
            <el-radio-group v-model="Form.sex">
              <el-radio label="1">男</el-radio>
              <el-radio label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="专业" prop="profession">
            <el-input
              v-model="Form.profession"
              type="text"
              size="small"
              placeholder="请输入专业"
              :disabled="disabled"
            />
          </el-form-item>
          <el-form-item label="工作单位" prop="workUnit">
            <el-input
              v-model="Form.workUnit"
              size="small"
              maxlength="50"
              show-word-limit
              placeholder="请输入工作单位"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属岗位" prop="professionTitle">
            <el-input
              v-model="Form.professionTitle"
              size="small"
              maxlength="50"
              show-word-limit
              placeholder="请输入所属岗位"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <el-button class="bgc-bv" @click="cancleForm('Form')">取消</el-button>
        <el-button class="bgc-bv" @click="getSure('Form')">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "teacherList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    var Phone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入联系人电话"));
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入联系人电话"));
      } else {
        callback();
      }
    };
    return {
      dialogFormVisible: false,
      teacherName: "",
      dialogVisible: false,
      dialogTitle: "添加学员",
      Form: {},
      rules: {
        userName: [
          { required: true, message: "请输入学员姓名", trigger: "blur" },
        ],
        mobile: [{ required: true, trigger: "blur", validator: Phone }],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        profession: [{ message: "请输入专业", trigger: "change" }],
        workUnit: [{ message: "请输入工作单位", trigger: "change" }],
        professionTitle: [{ message: "请输入所属岗位", trigger: "change" }],
      },
      userName: "",
      showtip: false,
      mobileObj: {},
      idcardmation: "",
    };
  },
  computed: {},
  created() {
    this.getTableHeight();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        courseId: this.$route.query.courseId,
        userName: this.userName,
      };
      this.doFetch(
        {
          url: "/courseUser/mini-course-user/courseUserList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    //新增
    handleAddStudent() {
      this.dialogVisible = true;
    },
    getSure(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.doeAjax();
        }
      });
    },
    doeAjax() {
      const parmar = {
        userName: this.Form.userName,
        idcard: this.Form.idCard,
        sex: this.Form.sex,
        mobile: this.Form.mobile,
        courseId: this.$route.query.courseId,
        professionTitle: this.Form.professionTitle,
        profession: this.Form.profession,
      };
      if (this.Form.workUnit) {
        parmar.workUnit = this.Form.workUnit;
      }

      this.$post("/courseUser/mini-course-user/addUser", parmar, 3000, true, 2)
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.cancleForm();
            this.getData();
            this.reset();
          }
        })
        .catch((err) => {
          return;
        });
    },
    //取消
    cancleForm(Form) {
      this.dialogVisible = false;
      this.$refs["Form"].resetFields();
      this.Form = {};
      this.getData();
    },
    getMobile(newValue) {
      if (newValue.length == 11) {
        this.$post(
          "/courseUser/mini-course-user/getUserByMobile",
          {
            mobile: newValue,
          },
          3000,
          true,
          2
        )
          .then((res) => {
            if (!res.data) {
              this.showtip = false;
            } else {
              this.showtip = true;
              this.mobileObj = res.data;
              this.idcardmation =
                res.data.userName + "/" + res.data.mobile || "";
            }
          })
          .catch((err) => {
            return;
          });
      } else {
        this.showtip = false;
      }
    },
    showInfo() {
      this.showtip = false;
      this.Form = {
        ...this.Form,
        ...this.mobileObj,
      };
    },
    // 导出
    handleCreate() {
      const params = {
        courseId: this.$route.query.courseId,
      };
      if (this.userName != null && this.userName != "") {
        params.userName = this.userName;
      }
      this.$post(
        "/courseUser/mini-course-user/listExport",
        params,
        3000,
        true,
        2
      ).then((res) => {
        if (res.status == "0") {
          //window.location.href = res.data;
          window.open(res.data);
        }
      });
    },
    // 删除
    handleDel(courseUserId) {
      this.doDel(
        {
          url: "/courseUser/mini-course-user/removeCourseUser",
          msg: "你确定要删除该学员吗？",
          ps: {
            type: "post",
            data: { courseUserId: courseUserId },
          },
        },
        true,
        2
      );
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 0.675) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    goHerf() {
        this.$router.push({
          path: "/web/miniApp/myResourcesList",
          query: {
            refresh: true,
          },
        });
    }
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refrsh == true) {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          (this.pageNum = 1), this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}

.operationControl > div > div > span {
  min-width: 6rem;
}

.itemLabel {
  display: inline-block;
  width: 3rem;
}
</style>
<style lang="less" scoped></style>
